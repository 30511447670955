import Hero from "../components/Hero.jsx";
import Intro from "../components/Intro.jsx";
import React from "react";
import {useOutletContext} from "react-router-dom";
import CourseLevel from "../components/Study/CourseLevel.jsx";
import SEO from "../components/SEO.jsx";
import GoogleMap from "../components/GoogleMap.jsx";
import {useTranslation} from "react-i18next";
import {realFilePath} from "../libs/utils";
import LastNews from "../components/LastNews";
import Widget from "../components/Widget/Widget";
import Slider from "../components/SliderMotion/Slider";
import ImageHolder from "../components/ImageHolder/ImageHolder";
import Modal, {useModal} from "../components/Modal/Modal";

const images = [
    {
        url: "https://plus.unsplash.com/premium_photo-1673292293042-cafd9c8a3ab3?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        caption: "Slika 1"
    },
    {
        url: "https://images.unsplash.com/photo-1466378743612-9da217f53e55?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        caption: "Slika 3"
    },
    "https://plus.unsplash.com/premium_photo-1675368244123-082a84cf3072?q=80&w=1900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    ,
    "https://plus.unsplash.com/premium_photo-1675826774815-35b8a48ddc2c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    ,
    "https://plus.unsplash.com/premium_photo-1673697239981-389164b7b87f?q=80&w=2044&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1466378743612-9da217f53e55?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    ,
    "https://plus.unsplash.com/premium_photo-1712685912274-2483dade540f?q=80&w=2075&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
];

function Home() {
    const {t} = useTranslation("locals", {keyPrefix: "home"});
    const lang = useOutletContext();
    return (
        <>
            <SEO
                title={"FIM - Početna"}
                description={"Fakultet za inžewerski menadžment"}
                name={"Fakultet za inžewerski menadžment"}
                type={"article"}
            />
            <Hero background={realFilePath("hero.jpg")} title={"Početna"} style={{minHeight: "90vh"}}>
                <Widget name={"hero_home"} lang={lang}/>
            </Hero>
            <Intro/>;
            <CourseLevel/>;
            <LastNews/>;
            <GoogleMap/>;
        </>
    );
}

export default Home;
