import React, {useMemo, useState} from 'react';
import "./style.scss";
import {realFilePath} from "../../libs/utils";
import {array} from "yup";

const ImageHolder = ({images = [], keys = {url: null, cb: null}, num = 3, caption = null, onClick = null}) => {
    const makeOdd = () => {
        if (num % 2 === 0) num -= 1;
        return num;
    };
    // const middleIndex = () => Math.floor(makeOdd() / 2);
    const middleIndex = useMemo(() => {
        return Math.floor(makeOdd() / 2);
    }, []);

    const imagesItem = useMemo(() => {
        if (images.length < num) {
            return images.concat([...images].splice(0, num - images.length));
        }
        return images;
    }, []);
    const truncateCaption = () => {
        if (caption && caption.length > 20) {
            return <h4>{caption.slice(0, 20) + "..."}</h4>;
        } else if (caption && caption.length < 21) {
            return <h4>{caption}</h4>;
        } else {
            return null;
        }
    };
    const renderBefore = () => {
        let beforeImages = [...imagesItem].slice(0, middleIndex);
        return beforeImages.map((image, index) => {
            return <div key={index} className="imageHolder-before"
                        style={{
                            transform: `rotate(${-10 * (index + 1)}deg)`,
                            zIndex: imagesItem.length - index
                        }}
            >
                <img src={image[keys.url] ? realFilePath(image[keys.url]) : image} alt=""/>
            </div>;
        });
    };
    const renderCenter = () => {
        let index = Math.floor(makeOdd() / 2);
        return <div className="imageHolder-middle" style={{
            zIndex: imagesItem.length + 1
        }}>
            <img src={imagesItem[index][keys.url] ? realFilePath(imagesItem[index][keys.url]) : imagesItem[index]}
                 alt=""/>
            {truncateCaption()}
        </div>;
    };
    const renderAfter = () => {
        let afterImages = [...imagesItem].slice(middleIndex + 1, num);
        // let afterImages = [...images].slice(0, middleIndex);
        return afterImages.map((image, index) => {
            return <div className="imageHolder-after" key={index}
                        style={{
                            transform: `rotate(${10 * (index + 1)}deg)`,
                            zIndex: imagesItem.length - index
                        }}
            ><img src={image[keys.url] ? realFilePath(image[keys.url]) : image} alt=""/></div>;
        });
    };


    return (
        <div className="imageHolder" onClick={onClick}>
            {renderBefore()}
            {renderCenter()}
            {renderAfter()}
        </div>
    );
};

export default ImageHolder;
