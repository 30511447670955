import ClientLayout from "../layouts/ClientLayout";
import {routes} from "./routes";
import AllNews from "../pages/AllNews";
import Home from "../pages/Home";
import AboutFakultet from "../pages/AboutFakultet";
import SnigleNews from "../pages/SnigleNews";
import Kontakt from "../pages/Kontakt/Kontakt";
import AkreditovaniCentar from "../pages/AccCentres/AkreditovaniCentar";
import CourseLevelSingle from "../pages/Study/CourseLevelSingle";
import StudyProgram from "../pages/Study/StudyProgram";
import NaukaLayout from "../pages/Nauka/NaukaLayout";
import Ebaza from "../pages/Nauka/Ebaza";
import Biblioteka from "../pages/Nauka/Biblioteka";
import Error404 from "../pages/Errors/Error404/Error404";
import StudyLevels from "../pages/Study/StudyLevels";
import AdmissionRules from "../pages/Admission/AdmissionRules";
import Admission from "../pages/Admission/Admission";
import About from "../pages/About";
import DekanskiKolegijum from "../pages/Osoblje/DekanskiKolegijum";
import NastavnoOsoblje from "../pages/Osoblje/NastavnoOsoblje";
import Nenastavno from "../pages/Osoblje/Nenastavno";
import OsobljeSingle from "../pages/Osoblje/OsobljeSingle";
import PublishLayout from "../pages/Publish/PublishLayout";
import Udzbenici from "../pages/Publish/Udzbenici";
import Journals from "../pages/Publish/Journals";
import CooperationLayout from "../pages/Cooperation/CooperationLayout";
import Error503 from "../pages/Errors/Error503/Error503";
import Partners from "../pages/Cooperation/Partners";
import GalleryPage from "../pages/Gallery/GalleryPage";


const lang = "en";
export const enRoutes = [{
    path: "/en",
    element: <ClientLayout lang={"en"}/>,
    children: [
        // HOME
        {
            path: routes.HOME.path.en,
            element: <Home/>
        },
        // ABOUT
        {
            path: routes.ABOUT.path.en,
            element: <About/>,
            children: [
                {
                    path: routes.ABOUT.path.en,
                    element: <AboutFakultet/>
                },
                {
                    path: routes.DEKANSKI_KOLEGIJUM.path.en,
                    element: <DekanskiKolegijum/>
                },
                {
                    path: routes.NASTAVNO_OSOBLJE.path.en,
                    element: <NastavnoOsoblje/>
                },
                {
                    path: routes.NENASTAVNO_OSOBLJE.path.en,
                    element: <Nenastavno/>
                }
            ]
        },
        {
            path: routes.GALLERY.path[lang],
            element: <GalleryPage/>
        },
        // OSOBLJE
        {
            path: routes.OSOBLJE_SINGLE.path.en,
            element: <OsobljeSingle/>
        },
        {
            path: routes.AKREDITOVANI_CENTAR.path.en,
            element: <AkreditovaniCentar/>
        },
        // STUDIJE
        {
            path: routes.COURSE_LEVEL.path.en,
            element: <StudyLevels/>
        },
        {
            path: routes.COURSE_LEVEL_SINGLE.path.en,
            element: <CourseLevelSingle/>
        },
        {
            path: routes.COURSE_STUDY_PROGRAM.path.en,
            element: <StudyProgram/>
        },
        // ENROLLMENT
        {
            path: routes.ENROLLMENT.path.en,
            element: <Admission/>,
            children: [
                {
                    path: routes.ENROLLMENT_LEVEL.path.en,
                    element: <AdmissionRules/>
                }
            ]
        },
        // VESTI
        {
            path: routes.NEWS.path.en,
            element: <AllNews/>
        },
        {
            path: routes.NEWS_SINGLE.path.en,
            element: <SnigleNews/>
        },
        // KONTAKT
        {
            path: routes.KONTAKT.paths.en,
            element: <Kontakt/>
        },
        // NAUKA
        {
            path: routes.NAUKA.paths.en,
            element: <NaukaLayout/>,
            children: [
                {
                    path: routes.NAUKA_EBAZA.paths.en,
                    element: <Ebaza/>
                },
                {
                    path: routes.NAUKA_BIBLIOTEKA.paths.en,
                    element: <Biblioteka/>
                }
            ]
        },

        // IZDAVAC
        {
            path: routes.IZDAVAC.paths[lang],
            element: <PublishLayout/>,
            children: [
                {
                    path: routes.IZDAVAC_CASOPIS.paths[lang],
                    element: <Journals/>
                },
                {
                    path: routes.IZDAVAC_UDZBENICI.paths[lang],
                    element: <Udzbenici/>
                }
            ]
        },

        // SARADNJA
        {
            path: routes.SARADNJA.paths[lang],
            element: <CooperationLayout/>,
            children: [
                {
                    path: routes.MEDJUNARODNA_SARADNJA.paths[lang],
                    element: <Partners/>
                },
                {
                    path: routes.POSLOVNA_SARADNJA.paths[lang],
                    element: <Partners/>
                }
            ]
        },


        {path: "/en/*", element: <Error404/>}
    ]
}];