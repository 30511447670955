import React, {useState} from 'react';
import Accordion from "../../components/Accordion/Accordion";
import {Link, useParams} from "react-router-dom";
import {routes} from "../../routes/routes";
import DOMParse from "html-react-parser";
import HTMLParse from "../../components/HTMLParse/HTMLParse";
import {useTranslation} from "react-i18next";

const colors = {
    closeBg: "#072248", openBg: "#072248",
    closeFont: "#ffffff", openFont: "#ffffff"
};

function StudyProgramDetail({studyPrograms}) {
    const [expanded, setExpanded] = useState([0]);
    const {t} = useTranslation();
    const {locale} = useParams();
    return studyPrograms.map((sp, index) => {
        return <Accordion title={sp.naziv} indexKey={index} key={sp.id}
                          expanded={expanded}
                          setExpanded={setExpanded}
                          colors={colors}
                          autoClose={true}
                          footerElement={<>
                              <div>
                                  <span className="btn btn-primary btn-sm">{sp.espb}</span>
                                  <span className="btn btn-primary btn-sm">{sp.trajanje}</span>
                                  <span className="btn btn-primary btn-sm">{sp.akreditovan}</span>
                              </div>
                              <Link className="btn btn_rounded btn_rounded-active"
                                    to={routes.COURSE_STUDY_PROGRAM.realPath(sp.id, locale)}>{t("buttons.readMore")}</Link>
                          </>}
        >
            <HTMLParse html={sp.opis}/>
        </Accordion>;
    });
}

export default StudyProgramDetail;