import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import "./style.scss";
import {createPortal} from "react-dom";
import {RemoveScroll} from "react-remove-scroll";
import {motion, AnimatePresence} from "framer-motion";

const ModalContext = createContext(null);

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({children}) => {
    const [modalContent, setModalContent] = useState(null);
    const [modalStyle, setModalStyle] = useState({});

    const showModal = (content, {style = {}}) => {
        setModalContent(content);
        setModalStyle(style);
    };
    const closeModal = () => setModalContent(null);

    return <ModalContext.Provider value={{showModal, closeModal, setModalStyle}}>
        {children}
        {createPortal(
            <AnimatePresence>
                {modalContent && <Modal closeModal={closeModal} modalStyle={modalStyle}>
                    {modalContent}
                </Modal>}
            </AnimatePresence>
            , document.getElementById("modal-portal"))}
    </ModalContext.Provider>;
};


const Modal = ({children, closeModal, modalStyle}) => {
    useEffect(() => {
        window.addEventListener("keydown", handleEvent);
        return () => {
            window.removeEventListener("keydown", handleEvent);
        };
    }, []);

    const handleEvent = (e) => {
        switch (e.key) {
            case "Escape":
                closeModal();
                break;
            default:
                break;
        }
    };

    return <RemoveScroll removeScrollBar={true}>
        <motion.div className="modal modal-container" style={modalStyle}
                    initial={{opacity: 0.1}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0.1}}
                    transition={{duration: 1}}
                    onClick={closeModal}>
            <motion.div className="modal-content"
                        initial={{opacity: 0.5, scale: 0, y: "100%"}}
                        animate={{opacity: 1, scale: 1, y: 0}}
                        exit={{opacity: 0.5, scale: 0, y: "100%"}}
                        transition={{duration: 1}}
            >
                {children}
            </motion.div>
        </motion.div>
    </RemoveScroll>;
};

export default Modal;