import React, {useEffect} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import {Link, useLocation, useOutletContext, useParams} from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";
import CooperationService from "../../services/CooperationService";
import {realFilePath} from "../../libs/utils";
import Widget from "../../components/Widget/Widget";
import Error204 from "../Errors/Error204/Error204";

const getRandomPosition = () => ({
    x: Math.random() * window.innerWidth * (Math.random() < 0.5 ? 1 : -1),
    y: Math.random() * window.innerHeight * (Math.random() < 0.5 ? 1 : -1)
});

const Partners = () => {
    const lang = useOutletContext();
    const location = useLocation();
    const {type} = useParams();
    const handleLink = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };
    const {data, isPending, error, refetch} = useFetchData(() => CooperationService.getPartners(type, lang));
    useEffect(() => {
        refetch(() => CooperationService.getPartners(type, lang));
    }, [type, lang]);

    return !isPending && (
        <section className="partners container py">
            <article className="partners_intro">
                <Widget name={`${type}_cooperation`} lang={lang}/>
            </article>
            {data !== null && data.length > 0 ? <article className="partners_card">
                <AnimatePresence key={location.pathname}>
                    {data.map((data, index) => (
                        <motion.article
                            key={index}
                            initial={{
                                ...getRandomPosition(),
                                opacity: 0
                            }}
                            animate={{
                                x: 0, y: 0, opacity: 1, rotate: 0,
                                transition: {
                                    delay: Math.random() * 1 + 1, // Nasumično kašnjenje
                                    duration: 1,
                                    ease: "linear"
                                }
                            }}
                            exit={{opacity: 0, ...getRandomPosition(), transition: {duration: 1}}}
                            onClick={() => handleLink(data.url)}
                        >
                            <div className="partners_item">
                                <div className="headLine" style={{backgroundColor: data.color || "#072248"}}></div>
                                <div className="partners_logo">
                                    <img src={realFilePath(data.logo)} alt=""
                                         style={
                                             {width: '100%', aspectRatio: "1", height: 'auto', objectFit: 'contain'}
                                         }/>
                                </div>
                                <div className="partners_body">
                                    <h5>{data.title}</h5>
                                </div>
                                <div className="partners_footer">
                                    <div className="partners_flag">
                                        <img src={realFilePath(data.flag)} alt=""/>
                                        <p>{data.state}</p>
                                    </div>
                                </div>
                            </div>
                        </motion.article>
                    ))}
                </AnimatePresence>
            </article> : <Error204 back={true}/>}
        </section>
    );
};

export default Partners;
