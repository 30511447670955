import React, {useEffect, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {FaChevronDown} from "react-icons/fa6";

function SelectList({name, captionKey, valueKey, options, onChange}) {
    const [drop, setDrop] = useState(false);
    const [caption, setCaption] = useState(options[0][captionKey]);
    useEffect(() => {
        onChange({target: {name, value: options[0][valueKey]}});
    }, []);

    const handleClick = (value, caption) => {
        setCaption(caption);
        onChange({
            target: {name, value}
        });
        setDrop(false);
    };

    return <div className="selectGroup">
        <button type="button" onClick={() => setDrop(!drop)}>
            <span>{caption}</span>
            <motion.span initial={"down"}
                         animate={drop ? "up" : "down"}
                         transition={{duration: .4}}
                         variants={{
                             up: {rotate: 180, transformOrigin: "center center"},
                             down: {rotate: 0, transformOrigin: "center center"}
                         }}
            ><FaChevronDown/></motion.span>
        </button>
        <AnimatePresence>
            {drop && <div className="dropList">
                <motion.div
                    initial="hidden"
                    animate={"show"}
                    exit="hidden"
                    transition={{duration: .4}}
                    variants={{
                        hidden: {translateY: "-101%"},
                        show: {translateY: 0}
                    }}
                >
                    {options.map((el, index) => (
                        <button type="button" key={index}
                                onClick={() => handleClick(el[valueKey], el[captionKey])}>
                            {el[captionKey]}
                        </button>
                    ))}
                </motion.div>
            </div>}
        </AnimatePresence>
    </div>;
}

export default SelectList;