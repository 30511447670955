import ClientLayout from "../layouts/ClientLayout";
import {routes} from "./routes";
import Home from "../pages/Home";
import About from "../pages/About";
import AboutFakultet from "../pages/AboutFakultet";
import DekanskiKolegijum from "../pages/Osoblje/DekanskiKolegijum";
import OsobljeSingle from "../pages/Osoblje/OsobljeSingle";
import DocumentsLayout from "../layouts/DocumentsLayout";
import DocumentsType from "../pages/Documents/DocumentsType";
import CourseLevelSingle from "../pages/Study/CourseLevelSingle";
import StudyProgram from "../pages/Study/StudyProgram";
import AllNews from "../pages/AllNews";
import SnigleNews from "../pages/SnigleNews";
import RasporedLayout from "../pages/Rasporedi/RasporedLayout";
import RasporedType from "../pages/Rasporedi/RasporedType";
import Kontakt from "../pages/Kontakt/Kontakt";
import NaukaLayout from "../pages/Nauka/NaukaLayout";
import Ebaza from "../pages/Nauka/Ebaza";
import Biblioteka from "../pages/Nauka/Biblioteka";
import Udzbenici from "../pages/Publish/Udzbenici";
import NastavnoOsoblje from "../pages/Osoblje/NastavnoOsoblje";
import Nenastavno from "../pages/Osoblje/Nenastavno";
import AkreditovaniCentar from "../pages/AccCentres/AkreditovaniCentar";
import Error404 from "../pages/Errors/Error404/Error404";
import Error503 from "../pages/Errors/Error503/Error503";
import PublishLayout from "../pages/Publish/PublishLayout";
import StudyLevels from "../pages/Study/StudyLevels";
import AdmissionRules from "../pages/Admission/AdmissionRules";
import Admission from "../pages/Admission/Admission";
import Journals from "../pages/Publish/Journals";
import WorkCalendar from "../pages/WorkCalendar/WorkCalendar";
import CooperationLayout from "../pages/Cooperation/CooperationLayout";
import Partners from "../pages/Cooperation/Partners";
import GalleryPage from "../pages/Gallery/GalleryPage";


// export const lang = localStorage.hasOwnProperty("lang") ? localStorage.getItem("lang") : "srb";
const lang = "srb";
export const srbRoutes = [{
    path: "/",
    element: <ClientLayout lang={"srb"}/>,
    children: [
        {
            path: "/calendar",
            element: <WorkCalendar/>
        },
        // HOME
        {
            path: routes.HOME.realPath(),
            element: <Home/>
        },
        // ABOUT
        {
            path: routes.ABOUT.path.srb,
            element: <About/>,
            children: [
                {
                    path: routes.ABOUT.path.srb,
                    element: <AboutFakultet/>
                },
                {
                    path: routes.DEKANSKI_KOLEGIJUM.path.srb,
                    element: <DekanskiKolegijum/>
                },
                {
                    path: routes.NASTAVNO_OSOBLJE.path.srb,
                    element: <NastavnoOsoblje/>
                },
                {
                    path: routes.NENASTAVNO_OSOBLJE.path.srb,
                    element: <Nenastavno/>
                }
            ]
        },
        // OSOBLJE
        {
            path: routes.OSOBLJE_SINGLE.path.srb,
            element: <OsobljeSingle/>
        },
        {
            path: routes.GALLERY.path[lang],
            element: <GalleryPage/>
        },
        {
            path: routes.AKREDITOVANI_CENTAR.path.srb,
            element: <AkreditovaniCentar/>
        },

        // ENROLLMENT
        {
            path: routes.ENROLLMENT.path.srb,
            element: <Admission/>,
            children: [
                {
                    path: routes.ENROLLMENT_LEVEL.path.srb,
                    element: <AdmissionRules/>
                }
            ]
        },
        // DOCUMENTS
        {
            path: routes.DOCUMENTS.realPath(),
            element: <DocumentsLayout/>,
            children: [
                {path: routes.DOCUMENTS_TYPE.paths.srb, element: <DocumentsType/>}
            ]
        },
        // STUDIJE
        {
            path: routes.COURSE_LEVEL.path.srb,
            element: <StudyLevels/>
        },
        {
            path: routes.COURSE_LEVEL_SINGLE.path.srb,
            element: <CourseLevelSingle/>
        },
        {
            path: routes.COURSE_STUDY_PROGRAM.path[lang],
            element: <StudyProgram/>
        },
        // VESTI
        {
            path: routes.NEWS.path.srb,
            element: <AllNews/>
        },
        {
            path: routes.NEWS_SINGLE.path[lang],
            element: <SnigleNews/>
        },
        // RASPOREDI
        {
            path: routes.RASPORED.paths[lang],
            element: <RasporedLayout/>,
            children: [
                {
                    path: routes.RASPORED_TYPE.paths[lang],
                    element: <RasporedType/>
                }
            ]
        },
        // {path: routes.RASPORED_POLAGANJA.paths[lang], element: <Polaganja/>},
        // KONTAKT
        {path: routes.KONTAKT.paths.srb, element: <Kontakt/>},
        // NAUKA
        {
            path: routes.NAUKA.paths[lang],
            element: <NaukaLayout/>,
            children: [
                {
                    path: routes.NAUKA_EBAZA.paths[lang],
                    element: <Ebaza/>
                },
                {
                    path: routes.NAUKA_BIBLIOTEKA.paths[lang],
                    element: <Biblioteka/>
                }
            ]


        },
        // IZDAVAC
        {
            path: routes.IZDAVAC.paths[lang],
            element: <PublishLayout/>,
            children: [
                {
                    path: routes.IZDAVAC_CASOPIS.paths[lang],
                    element: <Journals/>
                },
                {
                    path: routes.IZDAVAC_UDZBENICI.paths[lang],
                    element: <Udzbenici/>
                }
            ]
        },
        // SARADNJA
        {
            path: routes.SARADNJA.paths[lang],
            element: <CooperationLayout/>,
            children: [
                {
                    path: routes.MEDJUNARODNA_SARADNJA.paths[lang],
                    element: <Partners/>
                },
                {
                    path: routes.POSLOVNA_SARADNJA.paths[lang],
                    element: <Partners/>
                }
            ]
        },

        // {
        //     path: routes.SARADNJA.paths[lang],
        //     element: <IzdavacLayout/>,
        //     children: [
        //         {
        //             path: routes.MEDJUNARODNA_SARADNJA.paths[lang],
        //             element: <Error503/>
        //         },
        //         {
        //             path: routes.POSLOVNA_SARADNJA.paths[lang],
        //             element: <Error503/>
        //         }
        //     ]
        // },

        {path: "/*", element: <Error404/>}
    ]
}];