export const routes = {
    HOME: {
        path: {srb: "/", en: "/en"},
        realPath: (lang = "srb") => lang === "srb" ? "/" : `/${lang}`,
        caption: {
            srb: "Početna"
        }
    },
    // VESTI
    NEWS: {
        path: {srb: "/news", en: "/en/news"},
        realPath: (lang = "srb") => lang === "srb" ? "/news" : `/${lang}/news`,
        caption: {
            srb: "Vesti",
            en: "News"
        }
    },
    NEWS_SINGLE: {
        path: {srb: "/news/:id", en: "/en/news/:id"},
        realPath: (id, lang = "srb") => lang === "srb" ? `/news/${id}` : `/${lang}/news/${id}`,
        caption: {
            srb: "Vest",
            en: "Single news"
        }
    },

    // O FAKULTETU
    ABOUT: {
        path: {srb: "/about", en: "/en/about"},
        realPath: (lang = "srb") => lang === "srb" ? "/about" : `/${lang}/about`,
        caption: {
            srb: "O fakultetu", en: "About us"
        }
    },
    GALLERY: {
        path: {srb: "/gallery", en: "/en/gallery"}
    },
    DEKANSKI_KOLEGIJUM: {
        path: {srb: '/about/management', en: '/en/about/management'},
        realPath: (lang = "srb") => lang === "srb" ? "/about/management" : `/${lang}/about/management`,
        caption: {
            srb: 'Dekanski Kolegijum',
            en: 'FIM Management'
        }
    },
    NASTAVNO_OSOBLJE: {
        path: {srb: '/about/academic-staff', en: '/en/about/academic-staff'},
        realPath: (lang = "srb") => lang === "srb" ? "/about/academic-staff" : `/${lang}/about/academic-staff`,
        caption: {
            srb: 'Nastavno osoblje',
            en: "Academic staff"
        }
    },
    NENASTAVNO_OSOBLJE: {
        path: {srb: '/about/administrative-staff', en: '/en/about/administrative-staff'},
        realPath: (lang = "srb") => lang === "srb" ? "/about/administrative-staff" : `/${lang}/about/administrative-staff`,
        caption: {
            srb: 'Nenastavno osoblje',
            en: "Administrative staff"
        }
    },

    NASTAVNO_OSOBLJE_KATEGORIJA: {
        path: {srb: '/about/academic-staff', en: '/en/about/academic-staff'},
        realPath: (id, lang = "srb") => lang === "srb" ? `/about/academic-staff?category=${id}` : `/${lang}/about/academic-staff?category=${id}`,
        caption: {
            srb: ""
        }
    },

    OSOBLJE_SINGLE: {
        path: {srb: '/about/academic-staff/:id', en: '/en/about/academic-staff/:id'},
        realPath: (id, lang = "srb") => lang === "srb" ? `/about/academic-staff/${id}` : `/${lang}/about/academic-staff/${id}`,
        caption: {
            srb: ""
        }
    },
    AKREDITOVANI_CENTRI: {
        caption: {
            srb: "Akreditovani centri", en: "Accredited centres"
        },
        path: {srb: "/accredited-centres", en: "/en/accredited-centres"}
    },
    AKREDITOVANI_CENTAR: {
        caption: {
            srb: "Akreditovani centar", en: "Accredited centar"
        },
        path: {srb: "/accredited-centres/:slug", en: "/en/accredited-centres/:slug"},
        realPath: (slug, lang) => {
            return {
                srb: `/accredited-centres/${slug}`,
                en: `/${lang}/accredited-centres/${slug}`
            }[lang];
        }
    },


    // STUDIJE
    COURSE_LEVEL: {
        path: {srb: "/study/level", en: "/en/study/level"},
        caption: {
            srb: "Studije", en: "Study"
        }
    },
    COURSE_LEVEL_SINGLE: {
        path: {srb: "/study/level/:level", en: "/en/study/level/:level"},
        // realPath: (level, lang = "srb") => lang === "srb" ? `/about/academic-staff/${id}` : `/${lang}/about/academic-staff/${id}`,

        caption: {
            srb: "Nivo studija"
        },
        realPath: (level, lang) => {
            return {
                srb: `/study/level/${level}`,
                en: `/en/study/level/${level}`
            }[lang ? lang : "srb"];
        }
    },
    COURSE_STUDY_PROGRAM: {
        path: {srb: "/study/program/:id", en: "/en/study/program/:id"},
        caption: {
            srb: "Studijski program"
        },
        realPath: (level, lang = "srb") => {
            return {
                srb: `/study/program/${level}`,
                en: `/en/study/program/${level}`
            }[lang];
        }
    },

    // UPIS
    ENROLLMENT: {
        path: {srb: "/admissions", en: "/en/admissions"},
        caption: {srb: "Upis", en: "Admissions"}
    },
    ENROLLMENT_LEVEL: {
        path: {srb: "/admissions/:slug", en: "/en/admissions/:slug"},
        caption: {
            undergraduate: {srb: "Osnovne akademske studije", en: "Undergraduate study"},
            graduate: {srb: "Master akademske studije", en: "Graduate study"},
            phd: {srb: "Doktorske akademske studije", en: "Phd study"}
        },
        realPath: (slug, lang) => (
            {
                srb: `/admissions/${slug}`,
                en: `/en/admissions/${slug}`
            }
        )[lang]
    },
    ENROLLMENT_BASIC: {
        path: {srb: "/admissions/undergraduate", en: "/en/admissions/undergraduate"},
        caption: {srb: "Osnovne studije", en: "Undergraduate studies"},
        realPath: (lang = "srb") => lang === "srb" ? `/admissions/undergraduate` : `/${lang}/admissions/undergraduate`
    },
    ENROLLMENT_MASTER: {
        path: {srb: "/admissions/graduate", en: "/en/admissions/graduate"},
        caption: {srb: "Master studije", en: "Graduate studies"},
        realPath: (lang = "srb") => lang === "srb" ? `/admissions/graduate` : `/${lang}/admissions/graduate`
    },
    ENROLLMENT_DOCTORS: {
        path: {srb: "/admissions/phd", en: "/en/admissions/phd"},
        caption: {srb: "Doktorske studije", en: "PHD studies"},
        realPath: (lang = "srb") => lang === "srb" ? `/admissions/phd` : `/${lang}/admissions/phd`
    },

    // DOKUMENTA
    DOCUMENTS: {
        paths: {srb: "/documents"},
        realPath: (lang = "srb") => lang === "srb" ? `/documents` : `/${lang}/documents`,
        caption: {srb: "Dokumenta"}
    },
    DOCUMENTS_TYPE: {
        paths: {srb: "/documents/:type", en: null},
        realPath: (type, lang = "srb") => lang === "srb" ? `/documents/${type}` : `/${lang}/documents/${type}`,
        caption: {srb: ""}
    },
    // RASPOREDI
    RASPORED: {
        paths: {srb: "/schedule"}

    },
    RASPORED_TYPE: {
        paths: {srb: "/schedule/:slug"},
        caption: {srb: "Raspored predavanja"},
        realPath: (slug, lang = "srb") => {
            return {
                srb: `/schedule/${slug}`,
                en: `/schedule/${slug}`
            }[lang];
        }
    },
    RASPORED_POLAGANJA: {
        paths: {srb: "/schedule/lecture"},
        caption: {srb: "Raspored polaganja"}
    },

// KONTAKT
    KONTAKT: {
        paths: {srb: "/contact", en: "/en/contact"},
        caption: {srb: "Kontakt", en: "Contact"},
        realPath: (lang = "srb") => {
            return {
                srb: `/contact`,
                en: `/en/contact`
            }[lang];
        }
    },
// NAUKA
    NAUKA: {
        paths: {
            srb: "/science", en: "/en/science"
        },
        caption: {srb: "Nauka", en: "Science"}
    },
    NAUKA_EBAZA: {
        paths: {srb: "/science/database", en: "/en/science/database"},
        caption: {srb: "Naučni članci", en: "Scientific articles"},
        realPath: (lang) => ({
            srb: `/science/database`,
            en: `/en/science/database`
        })[lang]
    },
    NAUKA_BIBLIOTEKA: {
        paths: {srb: "/science/library", en: "/en/science/library"},
        caption: {srb: "Biblioteka", en: "Library"},
        realPath: (lang) => ({
            srb: `/science/library`,
            en: `/en/science/library`
        })[lang]
    },


//     IZDAVACKA DELATNOST
    IZDAVAC: {
        paths: {srb: "/publishing", en: "/en/publishing"},
        caption: {srb: "Izdavačka delatnost", en: "Publishing activity"}
    },
    IZDAVAC_UDZBENICI: {
        paths: {srb: "/publishing/textbooks", en: null},
        caption: {srb: "Monografija i udžbenici", en: null}
    },
    IZDAVAC_CASOPIS: {
        paths: {srb: "/publishing/journal", en: "/en/publishing/journal"},
        caption: {srb: "Naučno-stručni časopisi", en: "Scientific-professional journals"}
    },
//     SARADNJA
    SARADNJA: {
        caption: {srb: "Saradnja", en: "Cooperation"},
        paths: {srb: "/cooperation", en: "/en/cooperation"}
    },

    MEDJUNARODNA_SARADNJA: {
        caption: {srb: "Međunarodna saradnja", en: "International Cooperation"},
        paths: {srb: "/cooperation/:type", en: "/en/cooperation/:type"}
    },
    POSLOVNA_SARADNJA: {
        caption: {srb: "Privredna saradnja", en: "Corporate Cooperation"},
        paths: {srb: "/cooperation/:type", en: "/en/cooperation/:type"}
    }

};
