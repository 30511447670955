import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import "./style.scss";
import {FaChevronRight, FaChevronLeft} from "react-icons/fa6";
import {realFilePath} from "../../libs/utils";

const Slider = ({
                    images = [],
                    title = null,
                    loop = false,
                    duration = 1,
                    interval = 3000,
                    navigation = true,
                    indicator = true,
                    keys = {url: null, caption: null}
                }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const int = useRef(0);

    useEffect(() => {
        window.addEventListener("keydown", handleEvent);
        if (loop) {
            setLoop();
            return () => {
                window.removeEventListener("keydown", handleEvent);
                clearInterval(int.current);
            }; // Očisti interval pri unmount-u
        }
    }, []);

    // Resetuj poziciju slidera kada pređe granicu
    useEffect(() => {
        setTimeout(() => {
            if (currentIndex === images.length) {
                setIsTransitioning(false); // Ukloni animaciju za trenutni reset
                setCurrentIndex(0); // Vrati slider na početak
            } else {
                setIsTransitioning(false); // Omogući naredne tranzicije
            }
        }, duration * 1000); // Trajanje tranzicije treba da bude isto kao `duration` animacije

    }, [currentIndex]);

    const handleEvent = (e) => {
        switch (e.key) {
            case "ArrowRight":
                handleNext();
                break;
            case "ArrowLeft":
                handlePrev();
                break;
            default:
                break;
        }
    };

    const handleNext = (e) => {
        if (isTransitioning) return;
        setIsTransitioning(true); // Postavi na true pre tranzicije
        setDirection(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        resetInterval();

    };
    const handlePrev = (e) => {
        if (isTransitioning) return;
        setIsTransitioning(true); // Postavi na true pre tranzicije
        setDirection(false);
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        resetInterval();
    };

    const changeSlide = (index) => {
        if (isTransitioning) return;
        setIsTransitioning(true); // Postavi na true pre tranzicije
        setDirection((prevState) => {
            return loop ? !prevState : true;
        });
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const resetInterval = () => {
        if (loop) {
            clearInterval(int.current);
            setLoop();
        }
    };


    const setLoop = () => {
        int.current = setInterval(changeSlide, interval); // Automatski prelazak svakih 3 sekunde
    };

    return <div className="slider-container" onClick={(e) => {
        e.stopPropagation();
    }}>
        <div className="slider-wrapper">
            <AnimatePresence custom={direction}>
                <div>
                    <motion.img
                        onClick={(e) => {
                            handleNext();
                        }}
                        key={currentIndex}
                        src={images[currentIndex][keys.url] ? realFilePath(images[currentIndex][keys.url]) : images[currentIndex]}
                        alt={`Slide ${currentIndex}`}
                        initial={{opacity: .1}} // Početna pozicija
                        animate={{opacity: 1}} // Na poziciji
                        exit={{opacity: .1}} // Odlazak u suprotnom pravcu
                        transition={{duration: duration}}
                        className="slider-image"
                    />
                    {images[currentIndex][keys.caption] &&
                        <p className="slider-caption">{images[currentIndex][keys.caption]}</p>}
                </div>
            </AnimatePresence>
        </div>
        {title && <h4 className="slider-title">{title}</h4>}

        {navigation && <button onClick={handlePrev} className="slider-button slider-button-prev">
            <FaChevronLeft/>
        </button>}

        {navigation && <button onClick={handleNext} className="slider-button slider-button-next">
            <FaChevronRight/>
        </button>}


        {indicator && <div className="slider-indicator" onClick={(e) => {
            e.stopPropagation();
        }}>
            <ul>
                {images.map((dot, index) => {
                    return <li key={index} className={index === currentIndex ? "active" : null}
                               onClick={() => setCurrentIndex(index)}></li>;
                })}
            </ul>
        </div>}
    </div>;
};
export default Slider;
