import React, {useEffect} from 'react';
import Hero from "../../components/Hero";
import useFetchData from "../../hooks/useFetchData";
import GalleryService from "../../services/GalleryService";
import ImageHolder from "../../components/ImageHolder/ImageHolder";
import {realFilePath} from "../../libs/utils";
import Slider from "../../components/SliderMotion/Slider";
import {useModal} from "../../components/Modal/Modal";
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Error204 from "../Errors/Error204/Error204";

const GalleryPage = () => {
    const lang = useOutletContext();
    const {data, isPending, refetch} = useFetchData(() => GalleryService.getGallery(lang));
    const {showModal, closeModal} = useModal();
    const {t} = useTranslation("locals", {keyPrefix: "gallery"});
    useEffect(() => {
        refetch();
    }, [lang]);
    return !isPending && (
        <>
            <Hero background={"/image/gallery_hero.jpg"} title={"TEST"}>
                <h1>{t("title")}</h1>
            </Hero>
            <section className="container py">
                {data.length > 0 ? <article className="gallery">
                        {data.map((gallery, index) => {
                            return <ImageHolder key={index} images={gallery.images} num={7}
                                                keys={{url: "storeName", cb: realFilePath}}
                                                caption={gallery.title}
                                                onClick={() => {
                                                    showModal(
                                                        <Slider
                                                            images={gallery.images}
                                                            title={gallery.title}
                                                            keys={{url: "storeName", caption: "caption"}}
                                                        />,
                                                        {style: {background: "rgba(0,0,0,0.9)"}});

                                                }}

                            />;
                        })}
                    </article>
                    : <Error204/>}
            </section>
        </>
    );
};

export default GalleryPage;
