import React from 'react';
import DOMParse from "html-react-parser";
import "./style.scss";


const HTMLParse = ({html = ""}) => {
    return (
        <div className="parser_container">
            {DOMParse(html)}
        </div>
    );
};

export default HTMLParse;
