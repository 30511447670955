import React, {useEffect} from 'react';
import {Outlet, useOutletContext, useParams} from "react-router-dom";
import Hero from "../../components/Hero";
import {useTranslation} from "react-i18next";

const CooperationLayout = () => {
    const lang = useOutletContext();
    const {type} = useParams();
    const {t} = useTranslation();
    return (
        <>
            <Hero title={"Saradnja"} background={"/image/cooperation_hero.jpg"}>
                <h1>{t("cooperation.title")}</h1>
                <h3>{t(`cooperation.${type}`)}</h3>
            </Hero>
            <Outlet context={lang}/>
        </>
    );
};

export default CooperationLayout;
