import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import AccCenters from "../../services/AccCenters";
import Hero from "../../components/Hero";
import DOMParse from "html-react-parser";
import StudyProgramDetail from "../Study/StudyProgramDetail";
import {useTranslation} from "react-i18next";
import {realFilePath} from "../../libs/utils";
import {useSelector} from "react-redux";
import Error503 from "../Errors/Error503/Error503";
import HTMLParse from "../../components/HTMLParse/HTMLParse";

function AkreditovaniCentar() {
    const {t} = useTranslation("locals", {keyPrefix: "kontakt", useSuspense: true});
    const {slug} = useParams();
    const {lang} = useSelector(state => state.configStore);
    const [centar, setCentar] = useState(null);
    const [sp, setSp] = useState(null);
    useEffect(() => {
        AccCenters.getCenter(slug, lang)
            .then((res) => {
                console.log(res.data);
                setCentar(res.data.centar);
                setSp(res.data.studijskiProgrami);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [slug, lang]);
    return centar ? (
        <>
            <Hero background={realFilePath(centar.hero_image)} title={centar.naziv}>
                <h1>{centar.naziv}</h1>
            </Hero>
            <section className="akreditovaniCentar container py">
                <article>
                    <HTMLParse html={centar.detalji}/>
                </article>
                <article className="courseLevel_single_sp py-top">
                    <StudyProgramDetail studyPrograms={sp}/>
                </article>
                <article className="akreditovaniCentar_info grid grid-1-1 py-top">
                    <div className="adrress">
                        <dl>
                            <dt>{t("kontaktOsoba")}:</dt>
                            <dd>{centar.kontakt_osoba}</dd>
                            <dt>{t("telefon")}:</dt>
                            <dd>{centar.telefon}</dd>
                            <dt>Email:</dt>
                            <dd>{centar.email}</dd>
                        </dl>
                    </div>
                    <div className="adrress">
                        <p><strong>{t("adresa.title")}:</strong></p>
                        <HTMLParse html={centar.adresa}/>
                    </div>
                </article>
            </section>
            <section className={"googleMap_100vw"}>
                <HTMLParse html={centar.goole_map}/>
            </section>

        </>
    ) : <Error503/>;
}

export default AkreditovaniCentar;
