import {useEffect, useState} from "react";

function useFetchData(fetchFn) {
    const [isPending, setIsPending] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const request = async (fn) => {
        try {
            setError(null);
            setIsPending(true);
            let response = await fn();

            if (response.status === 200) {

                setData(response.data);
            } else (
                setData(null)
            );
        } catch (err) {
            setError(err.message);
        } finally {
            setIsPending(false);
        }
    };

    useEffect(() => {
        request(fetchFn);
    }, []);

    const refetch = (newRequest = fetchFn) => request(newRequest);

    return {data, isPending, error, refetch};
}

export default useFetchData;