import React, {useEffect, useState} from 'react';
import {Link, useOutletContext, useParams} from "react-router-dom";
import HomePage from "../../services/HomePage";
import Accordion from "../../components/Accordion/Accordion";
import {routes} from "../../routes/routes";
import DOMParse from "html-react-parser";
import Hero from "../../components/Hero";
import {useTranslation} from "react-i18next";
import {realFilePath} from "../../libs/utils";
import HTMLParse from "../../components/HTMLParse/HTMLParse";

const colors = {
    closeBg: "#072248", openBg: "#072248",
    closeFont: "#ffffff", openFont: "#ffffff"
};

function StudyLevels(props) {
    const [studyLevels, setStudyLevels] = useState([]);
    const [expanded, setExpanded] = useState([0]);
    const {t} = useTranslation();
    const locale = useOutletContext();
    const params = useParams();
    useEffect(() => {
        HomePage.getCourseLevel(locale)
            .then((res) => {
                setStudyLevels(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [locale]);

    return <>
        <Hero background={realFilePath("hero_study.jpg")} title={routes.COURSE_LEVEL.caption[locale]}>
            <h1>{routes.COURSE_LEVEL.caption[locale]}</h1>
        </Hero>
        <section className="container py">
            {studyLevels.length > 0 && studyLevels.map((study, index) => {
                    return <Accordion title={study.title} indexKey={index} key={study.id}
                                      expanded={expanded}
                                      setExpanded={setExpanded}
                                      colors={colors}
                                      autoClose={true}
                                      footerStyle={{textAlign: "right"}}
                                      footerElement={
                                          <Link className="btn btn_rounded btn_rounded-primary"
                                                to={routes.COURSE_LEVEL_SINGLE.realPath(study.slug, locale)}>{t("buttons.readMore")}</Link>
                                      }
                    >
                        <HTMLParse html={study.description}/>
                    </Accordion>;
                }
            )}
        </section>
    </>;
}

export default StudyLevels;